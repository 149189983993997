import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonResponse, GwApiUtilsFormMappings } from '@common';

@Injectable({
  providedIn: 'root'
})
export class AdminGwHttpService {
  constructor(private http: HttpClient) {}

  /**
   * This is new as of #3579, where this endpoint dispatches a sync for the given
   * resource.
   */
  public syncViaGcf(syncResource: keyof GwApiUtilsFormMappings) {
    return this.http.post<CommonResponse>(`/api/v1/admin/gwapps/sync`, {
      syncResource
    });
  }

  public syncUserTests() {
    return this.http.post<CommonResponse>('/api/v1/admin/gwapps/test/sync', {});
  }

  public syncCompanies() {
    return this.http.post<CommonResponse>(
      '/api/v1/admin/gwapps/company/sync',
      {}
    );
  }

  public syncVaccination() {
    return this.http.post<CommonResponse>(
      '/api/v1/admin/gwapps/vaccination/sync',
      {}
    );
  }

  public syncEventServices() {
    return this.http.post<CommonResponse>(
      '/api/v1/admin/gwapps/service/sync',
      {}
    );
  }

  public syncEventLocations() {
    return this.http.post<CommonResponse>(
      '/api/v1/admin/gwapps/event/sync',
      {}
    );
  }

  public syncCustomConsent() {
    return this.http.post<CommonResponse>(
      '/api/v1/admin/gwapps/consent/sync',
      {}
    );
  }

  public syncHealthPrograms() {
    return this.http.post<CommonResponse>(
      '/api/v1/admin/gwapps/health-programs/sync',
      {}
    );
  }

  public syncInsuranceProviders() {
    return this.http.post<CommonResponse>(
      '/api/v1/admin/gwapps/insurance/sync',
      {}
    );
  }

  public syncCustomPricing() {
    return this.http.post<CommonResponse>(
      '/api/v1/admin/gwapps/pricing/sync',
      {}
    );
  }

  public syncArticles() {
    return this.http.post<CommonResponse>(
      '/api/v1/admin/gwapps/articles/sync',
      {}
    );
  }

  public syncArticleLinks() {
    return this.http.post<CommonResponse>(
      '/api/v1/admin/gwapps/articles/link/sync',
      {}
    );
  }

  public syncIncentives() {
    return this.http.post<CommonResponse>(
      '/api/v1/admin/gwapps/incentives/sync',
      {}
    );
  }

  public syncActivityBadges() {
    return this.http.post<CommonResponse>(
      '/api/v1/admin/gwapps/activity-badges/sync',
      {}
    );
  }

  public syncServiceConditionalTests() {
    return this.http.post<CommonResponse>(
      '/api/v1/admin/gwapps/conditional-test/sync',
      {}
    );
  }

  public getGwappsErrors(params: {
    type: keyof GwApiUtilsFormMappings;
    id: string;
  }) {
    const { type, id } = params;

    return this.http.get<unknown>(`api/v1/admin/gwapps/errors/${type}/${id}`);
  }
}
