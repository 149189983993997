import { Injectable } from '@angular/core';
import {
  AdminListUserRegistrationsParams,
  AdminUser,
  Company,
  getId,
  HpfUploadNote,
  isAdminListUserRegistrations,
  isProviderHealthUserRegistration,
  OffsiteUserRegistration,
  OnsiteUserRegistration,
  ProviderHealthUploadFilter,
  ProviderHealthUploadStatus,
  ProviderHealthUserRegistration,
  User,
  UserRegistration,
  UserRegistrationId
} from '@common';
import {
  EntityFacade,
  selectQueryParam,
  selectRouteNestedParam
} from '@ehs-ngrx/common';
import { createSelector, select, Store } from '@ngrx/store';
import { take, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { userRegistrationNgrxActions } from './user-registration-ngrx.actions';
import { EventLocation } from '@common';
import {
  userRegistrationNgrxFeatureSelector,
  userRegistrationNgrxSelector
} from './user-registration-ngrx.selector';
import {
  ParentUserRegistrationNgrxState,
  UserRegistrationNgrxState
} from './user-registration-ngrx.state';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { AdminUserRegistrationHttpService } from '@http';

@Injectable({
  providedIn: 'root'
})
export class UserRegistrationNgrxFacade extends EntityFacade<
  UserRegistration,
  string,
  ParentUserRegistrationNgrxState,
  UserRegistrationNgrxState
> {
  /**
   * The ids selected
   */
  public selectedIds$ = this.store.pipe(
    select(userRegistrationNgrxSelector.selectedSelector)
  );

  /**
   * The user-registrations selected
   */
  public selected$ = this.store.pipe(
    select(userRegistrationNgrxSelector.selectedEntitiesArrSelector)
  );

  /**
   * Returns the number of selected user-registrations
   */
  public selectedLength$ = this.store.pipe(
    select(userRegistrationNgrxSelector.selectedLengthSelector)
  );

  /**
   * Returns if there are any selected at all
   */
  public hasSelected$ = this.store.pipe(
    select(userRegistrationNgrxSelector.hasSelectedSelector)
  );

  /**
   * Returns a map of user-registrations by their user property
   */
  public byUser$ = this.store.pipe(
    select(userRegistrationNgrxSelector.getByUserSelector)
  );

  public onCreateSuccess$ = this.actions$.pipe(
    ofType(userRegistrationNgrxActions.createWalkinRegistration.success)
  );

  public onCreateAdditionalSuccess$ = this.actions$.pipe(
    ofType(
      userRegistrationNgrxActions.createAdditionalWalkinRegistration.success
    )
  );

  public hasNext$ = this.store.select(
    userRegistrationNgrxSelector.hasNextSelector
  );

  public pageNumber$ = this.store.select(
    userRegistrationNgrxSelector.pageNumberSelector
  );

  public providerHealthUploads$ = this.entitiesArray$.pipe(
    map((userRegistrations) =>
      (
        userRegistrations
          // EHS doesn't want to see 'not_uploaded' results
          .filter(
            (userRegistration) =>
              isProviderHealthUserRegistration(userRegistration) &&
              userRegistration.uploadStatus !==
                ProviderHealthUploadStatus.NOT_UPLOADED
          )
          .map((reg) => reg as ProviderHealthUserRegistration) || []
      ).sort(this.sortUploads)
    )
  );

  public providerResultsLoading$ = new BehaviorSubject(false);

  constructor(
    store: Store<ParentUserRegistrationNgrxState>,
    private actions$: Actions,
    private adminUserRegistrationHttp: AdminUserRegistrationHttpService
  ) {
    super(store, userRegistrationNgrxFeatureSelector);
  }

  // Used for the health provider upload table
  public listByFilter(filter: ProviderHealthUploadFilter) {
    this.store.dispatch(userRegistrationNgrxActions.listByFilter.req(filter));
  }

  // Used in Client Admin User Table
  public listByCompany(company: Company | string) {
    this.store.dispatch(
      userRegistrationNgrxActions.listByCompany.req({ company })
    );
  }

  // Used for the health provider upload table
  public loadMore(filter: ProviderHealthUploadFilter) {
    this.store.dispatch(userRegistrationNgrxActions.loadMore.req(filter));
  }

  // Used for the health provider upload table
  public setPageNumber(pageNumber: number) {
    this.store.dispatch(
      userRegistrationNgrxActions.setPageNumber({ pageNumber })
    );
  }

  // Used for the health provider upload table
  public clear() {
    this.store.dispatch(userRegistrationNgrxActions.clear());
  }

  /**
   * Returns the user-requisition for the given user-registration-id
   */
  public getReqByReg$(id: UserRegistrationId) {
    return this.store.pipe(
      select(userRegistrationNgrxSelector.getReqByRegSelector(id))
    );
  }

  /**
   * Clear All registrations from the store
   */
  public clearAll() {
    this.store.dispatch(userRegistrationNgrxActions.clearAll());
  }

  /**
   * Clear the selected user-registrations.
   */
  public selectionClear() {
    this.store.dispatch(userRegistrationNgrxActions.selectionClear());
  }

  public select(userRegistration: UserRegistration | UserRegistrationId) {
    this.store.dispatch(
      userRegistrationNgrxActions.select({
        id: UserRegistrationId(getId(userRegistration))
      })
    );
  }

  public unselect(userRegistration: UserRegistration | UserRegistrationId) {
    this.store.dispatch(
      userRegistrationNgrxActions.unselect({
        id: UserRegistrationId(getId(userRegistration))
      })
    );
  }

  public selectMany(
    userRegistrations: Array<UserRegistration | UserRegistrationId>
  ) {
    this.store.dispatch(
      userRegistrationNgrxActions.selectMany({
        ids: userRegistrations.map((userRegistration) =>
          UserRegistrationId(getId(userRegistration))
        )
      })
    );
  }

  public unselectMany(
    userRegistrations: Array<UserRegistration | UserRegistrationId>
  ) {
    this.store.dispatch(
      userRegistrationNgrxActions.unselectMany({
        ids: userRegistrations.map((userRegistration) =>
          UserRegistrationId(getId(userRegistration))
        )
      })
    );
  }

  public selectionToggle(
    userRegistration: UserRegistration | UserRegistrationId
  ) {
    this.store.dispatch(
      userRegistrationNgrxActions.selectionToggle({
        id: UserRegistrationId(getId(userRegistration))
      })
    );
  }

  public get(id: string) {
    this.store.dispatch(userRegistrationNgrxActions.get.req({ id }));
  }

  public updateOptionalTests(params: { id: string; optionalTests: string[] }) {
    this.store.dispatch(
      userRegistrationNgrxActions.updateOptionalTests.req(params)
    );
  }

  /**
   * This will get the corresponding user-requisition relative
   * to the given user-registration. This should be used to "get"
   * the user-req's pdf.
   *
   * This won't work for provider-health user-registrations.
   */
  public getUserReq(
    userRegistration:
      | OnsiteUserRegistration
      | OffsiteUserRegistration
      | UserRegistrationId
  ) {
    this.store.dispatch(
      userRegistrationNgrxActions.getUserReq.req({
        userRegistration: UserRegistrationId(getId(userRegistration))
      })
    );
  }

  public getByUser$(userId: string | User) {
    return this.store.select(
      createSelector(
        userRegistrationNgrxSelector.getByUserSelector,
        (byUsers) => byUsers[getId(userId)]
      )
    );
  }

  /**
   * This will make a gall to get multiple user-requisitions from the back-end.
   */
  public getUserReqs(params: {
    userRegistrations: Array<
      OnsiteUserRegistration | OffsiteUserRegistration | UserRegistrationId
    >;
  }) {
    const { userRegistrations } = params;

    this.store.dispatch(
      userRegistrationNgrxActions.getUserReqs.req({
        userRegistrations: userRegistrations.map((userRegistration) =>
          UserRegistrationId(getId(userRegistration))
        )
      })
    );
  }

  /**
   * List user-registrations, for specific scenarios
   */
  public listAsAdmin(params: AdminListUserRegistrationsParams) {
    this.store.dispatch(
      userRegistrationNgrxActions.listAsAdmin.req({
        ...params,
        eventService: isAdminListUserRegistrations.byService(params)
          ? getId(params.eventService)
          : undefined,
        eventLocation: isAdminListUserRegistrations.byEvent(params)
          ? getId(params.eventLocation)
          : undefined,
        user: isAdminListUserRegistrations.byUser(params)
          ? getId(params.user)
          : '' // Check if this has any negative effects.
      })
    );
  }

  /**
   * Lists the user-registrations as an admin for the given service
   * in the route, checks against `eventServiceId`
   */
  public listAsAdminForServiceRoute(
    params?: Omit<
      AdminListUserRegistrationsParams,
      'eventService' | 'eventLocation' | 'user'
    >
  ) {
    this.store
      .pipe(select(selectRouteNestedParam('eventServiceId')), take(1))
      .subscribe(
        (eventService) =>
          eventService &&
          this.listAsAdmin({
            ...(params || {}),
            eventService
          })
      );
  }

  /**
   * Lists the user-registrations as an admin for the given
   * event in the route, checks against event-location-id
   */
  public listAsAdminForEventRoute(
    params?: Omit<AdminListUserRegistrationsParams, 'eventService' | 'user'>
  ) {
    this.store
      .pipe(select(selectRouteNestedParam('eventLocationId')), take(1))
      .subscribe(
        (eventLocation) =>
          eventLocation &&
          this.listAsAdmin({
            ...(params || {}),
            eventLocation
          })
      );
  }

  /**
   * Lists the user-registrations as an admin for the given service
   * in the query-params, checks against `eventService`
   */
  public listAsAdminForServiceQueryParam(
    params?: Omit<
      AdminListUserRegistrationsParams,
      'eventService' | 'eventLocation' | 'user'
    >
  ) {
    this.store
      .pipe(select(selectQueryParam('eventService')), take(1))
      .subscribe(
        (eventService) =>
          eventService &&
          this.store.dispatch(
            userRegistrationNgrxActions.listAsAdmin.req({
              ...(params || {}),
              eventService
            })
          )
      );
  }

  /**
   * Removes the user-registration and user-result, if there is one.
   *
   * **note** internally this uses the user-result endpoint, which is weird.
   */
  public remove(userRegistration: UserRegistration | UserRegistrationId) {
    this.store.dispatch(
      userRegistrationNgrxActions.remove.req({
        id: UserRegistrationId(getId(userRegistration))
      })
    );
  }

  public removeVaccinationAndRegistration(
    userRegistration: UserRegistration | UserRegistrationId
  ) {
    this.store.dispatch(
      userRegistrationNgrxActions.removeVaccinationAndRegistration.req({
        id: UserRegistrationId(getId(userRegistration))
      })
    );
  }

  public removeRegistrationOnly(
    userRegistration: UserRegistration | UserRegistrationId
  ) {
    this.store.dispatch(
      userRegistrationNgrxActions.removeRegistrationOnly.req({
        id: UserRegistrationId(getId(userRegistration))
      })
    );
  }

  public resendConfirmationEmail(userRegistration: UserRegistration) {
    this.store.dispatch(
      userRegistrationNgrxActions.resendConfirmationEmail.req({
        id: getId(userRegistration) as UserRegistrationId
      })
    );
  }

  /**
   * Releases the given user-registrations to the labs.
   * Only supports onsite and offsite user-registrations, where
   * offsite has been added as of #2876
   */
  public release(params: {
    userRegistrations:
      | OnsiteUserRegistration[]
      | OffsiteUserRegistration[]
      | UserRegistrationId[];
  }) {
    const { userRegistrations } = params;

    this.store.dispatch(
      userRegistrationNgrxActions.release.req({
        ids: userRegistrations.map(getId).map(UserRegistrationId)
      })
    );
  }

  /**
   * Send the user-registration to the Lab
   * Only supports onsite and offsite user-registrations
   */
  public sendOrderToLab(params: {
    userRegistrations:
      | OnsiteUserRegistration[]
      | OffsiteUserRegistration[]
      | UserRegistrationId[];
    skipDialog?: boolean;
    resend?: boolean;
  }) {
    const { userRegistrations, skipDialog, resend } = params;

    if (skipDialog) {
      this.store.dispatch(
        userRegistrationNgrxActions.sendToLab.req({
          ids: userRegistrations.map(getId).map(UserRegistrationId),
          resend
        })
      );

      return;
    }

    this.store.dispatch(
      userRegistrationNgrxActions.sendToLab.prompt({
        ids: userRegistrations.map(getId).map(UserRegistrationId),
        resend
      })
    );
  }

  /**
   * Send all user-registrations for a given event-location to the lab
   * Only supports onsite
   */
  public sendEventOrdersToLab(params: {
    eventLocation: string | EventLocation;
    admin?: AdminUser;
  }) {
    const { eventLocation, admin } = params;

    this.store.dispatch(
      userRegistrationNgrxActions.sendToLab.prompt({
        eventLocation: getId(eventLocation),
        admin
      })
    );
  }

  /**
   * Export registrations to csv for client-admin
   */
  public exportRegistrationsToCsv(params: {
    company: string;
    eventLocation?: string;
  }) {
    const { company, eventLocation } = params;

    this.store.dispatch(
      userRegistrationNgrxActions.exportToCsv.req({
        company,
        eventLocation
      })
    );
  }

  /**
   * Mark selected user registrations as no-shows
   */
  public markNoShow(params: { userRegistrations: OnsiteUserRegistration[] }) {
    const { userRegistrations } = params;

    this.store.dispatch(
      userRegistrationNgrxActions.markNoShow.prompt({
        userRegistrations
      })
    );
  }

  public removeVaccination(params: {
    userRegistrations: OnsiteUserRegistration[];
  }) {
    const { userRegistrations } = params;

    this.store.dispatch(
      userRegistrationNgrxActions.removeVaccination.prompt({
        userRegistrations
      })
    );
  }

  /**
   * Mark selected user registrations as completed, primarily used if mark no-show is accidentally selected
   */
  public markCompleted(params: {
    userRegistrations: OnsiteUserRegistration[];
  }) {
    const { userRegistrations } = params;

    this.store.dispatch(
      userRegistrationNgrxActions.markCompleted.prompt({
        userRegistrations
      })
    );
  }

  /**
   * Create User Registration, #3957
   */
  public createWalkinRegistration(userRegistration: Partial<UserRegistration>) {
    return this.store.dispatch(
      userRegistrationNgrxActions.createWalkinRegistration.req({
        entity: userRegistration
      })
    );
  }

  public createAdditionalWalkinRegistration(
    userRegistration: Partial<UserRegistration>
  ) {
    return this.store.dispatch(
      userRegistrationNgrxActions.createAdditionalWalkinRegistration.req({
        entity: userRegistration
      })
    );
  }

  /**
   * Reschedule User Registration Dialog
   */
  public rescheduleRegistration(userRegistration: Partial<UserRegistration>) {
    return this.store.dispatch(
      userRegistrationNgrxActions.reschedule.req({
        entity: userRegistration
      })
    );
  }

  // Pass userRegistration with updated eventLocation,date and time
  public updateOnsiteEventDate(userRegistration: OnsiteUserRegistration) {
    this.store.dispatch(
      userRegistrationNgrxActions.changeOnsiteEventDate.req({
        entity: userRegistration
      })
    );
  }

  public updateRegistrationDetails(
    userRegistration: Partial<UserRegistration>
  ) {
    this.store.dispatch(
      userRegistrationNgrxActions.updateRegistrationDetails.req({
        userRegistration: userRegistration
      })
    );
  }

  // Search Alternative to using Fuse.js, looking for exact match
  public searchFn$(params: {
    /**
     * Observable search string applied to the given keys.
     */
    search$: Observable<string>;
    /**
     * An observable to the filter function,
     * will automatically be applied **before**
     * searching is applied.
     *
     * This is an observable so this function can be
     * "triggered" when the filter changes.
     */
    filterFn$?: Observable<
      (
        entity: UserRegistration,
        index?: number,
        arr?: UserRegistration[]
      ) => boolean
    >;
    /** Need users to get to firstName and lastName
     * tried doing this directly in function with userNgrxSelector, but couldn't make it work so going to get from facade when called and pass as parameter
     */
    users$: Observable<Record<string, User>>;
  }) {
    const { search$, filterFn$, users$ } = params;
    const filteredEntities$ = combineLatest([
      this.entitiesArray$.pipe(distinctUntilChanged()),
      filterFn$ || of(() => true)
    ]).pipe(map(([entities, filterFn]) => (entities || []).filter(filterFn)));

    return combineLatest([
      search$,
      filteredEntities$,
      users$.pipe(distinctUntilChanged())
    ]).pipe(
      map(([search, filteredEntities, users]) =>
        filteredEntities.length && search.length > 2
          ? filteredEntities.filter((entity) => {
              const user: User = users[getId(entity.user)];

              return (
                (user?.firstName || '')
                  .toLowerCase()
                  .indexOf((search || '').toLowerCase()) !== -1 ||
                (user?.lastName || '')
                  .toLowerCase()
                  .indexOf((search || '').toLowerCase()) !== -1
              );
            })
          : filteredEntities || []
      )
    );
  }

  private sortUploads(a: UserRegistration, b: UserRegistration): number {
    return a?.updatedAt < b?.updatedAt ? -1 : 1;
  }

  /**
   * Downloads provider health results from google cloud for users
   */
  public downloadProviderResults(params: { id: string; fileId: string }) {
    const { id, fileId } = params;

    this.providerResultsLoading$.next(true);

    return this.adminUserRegistrationHttp
      .getProviderDownloadSignedUrl({ fileId, id })
      .pipe(
        tap(({ url }) => window.open(url, '_blank')),
        tap(() => this.providerResultsLoading$.next(false))
      )
      .subscribe();
  }

  public updateNote({
    note,
    userRegistrationId
  }: {
    note: HpfUploadNote;
    userRegistrationId: string;
  }) {
    this.get$(userRegistrationId)
      .pipe(take(1))
      .subscribe((userRegistration) => {
        if (isProviderHealthUserRegistration(userRegistration)) {
          return this.store.dispatch(
            userRegistrationNgrxActions.updateNotes.req({
              entity: {
                ...userRegistration,
                notes: (userRegistration?.notes || []).map((existingNote) =>
                  getId(existingNote) === getId(note) ? note : existingNote
                )
              }
            })
          );
        }

        return;
      });
  }

  public removeNote({
    noteId,
    userRegistrationId
  }: {
    noteId: string;
    userRegistrationId: string;
  }) {
    this.get$(userRegistrationId)
      .pipe(take(1))
      .subscribe((userRegistration) => {
        if (isProviderHealthUserRegistration(userRegistration)) {
          return this.store.dispatch(
            userRegistrationNgrxActions.updateNotes.req({
              entity: {
                ...userRegistration,
                notes: (userRegistration?.notes || []).filter(
                  (existingNote) => getId(existingNote) !== noteId
                )
              }
            })
          );
        }
      });
  }

  public addNote({
    admin,
    userRegistrationId
  }: {
    admin: AdminUser;
    userRegistrationId: string;
  }) {
    this.get$(userRegistrationId)
      .pipe(take(1))
      .subscribe((userRegistration) => {
        if (isProviderHealthUserRegistration(userRegistration)) {
          return this.store.dispatch(
            userRegistrationNgrxActions.updateNotes.req({
              entity: {
                ...userRegistration,
                notes: [
                  ...(userRegistration.notes || []),
                  {
                    status: ProviderHealthUploadStatus.REJECTED,
                    notes: '',
                    admin: {
                      _id: getId(admin),
                      email: admin.email,
                      firstName: admin.firstName,
                      lastName: admin.lastName
                    },
                    createdAt: new Date(),
                    attachments: userRegistration.uploadFiles
                  } as HpfUploadNote
                ]
              }
            })
          );
        }
      });
  }
}
