import { Injectable } from '@angular/core';
import {
  Company,
  DateUtil,
  PartialUser,
  User,
  UserCritical,
  UserPayment,
  UserUtil,
  UserHomeTest,
  LocationDataUtil,
  LocationData,
  UserIncentive
} from '@common';
import { EhsUserInfoColumn } from './ehs-user-info-column';

/**
 * This service provides some basic methods that return pre-set columns
 * for different situations within the app. This also provides
 * methods to "transform" some objects
 */
@Injectable({
  providedIn: 'root'
})
export class EhsUserInfoService {
  /**
   * Returns the user data that can be passed into the ehs-user-info component.
   * This will "merge" the user-payment with a user, if available.
   */
  public getUserFromUserCritical({
    userCritical,
    user
  }: {
    userCritical: UserCritical;
    user: User;
  }): Partial<User> | Partial<PartialUser> {
    return {
      ...userCritical,
      ...user
    };
  }

  /**
   * Returns the columns for the user-page. This displays
   * a good amount of information, but may change in the future
   */
  public getUserPageColumns(params?: {
    company?: Company;
  }): EhsUserInfoColumn[] {
    return [
      {
        title: 'First Name',
        prop: 'firstName',
        nameCase: true
      },
      {
        title: 'Last Name',
        prop: 'lastName',
        nameCase: true
      },
      {
        title: 'Phone',
        prop: 'phone'
      },
      {
        title: 'Email',
        prop: 'email'
      },
      {
        title: 'Gender',
        prop: 'gender',
        titleCase: true
      },
      {
        title: 'Birthday (Age)',
        override: ({ user }) =>
          DateUtil.formatDate(user.birthDay) +
          ' (' +
          UserUtil.getAge({ birthDay: user.birthDay }) +
          ')'
      },
      {
        title: 'SSN',
        prop: 'ssn'
      },
      ...(params?.company
        ? [
            {
              title: 'Company',
              override: params.company.name
            }
          ]
        : [])
    ];
  }

  /**
   * The list of columns displayed on the user-criticals component.
   * This maps columns from user-criticals.
   */
  public getUserCriticalsColumns({
    companyName
  }: {
    /**
     * The company name we will display for this user-critical.
     * This is required, as the `user` wont have this information by default.
     *
     * **note** this was taken from EhsPlatformUserCriticalsProfileComponent
     */
    companyName: string;
  }): EhsUserInfoColumn[] {
    return [
      {
        title: 'First Name',
        prop: 'firstName',
        nameCase: true
      },
      {
        title: 'Last Name',
        prop: 'lastName',
        nameCase: true
      },
      {
        title: 'Phone',
        prop: 'phone'
      },
      {
        // **Note** this property has a template override
        // check the component for implementation
        title: 'Email',
        prop: 'email',
        templateOverride: true
      },
      {
        title: 'Gender',
        prop: 'gender',
        titleCase: true
      },
      {
        title: 'Age',
        override: ({ user }) =>
          '' + UserUtil.getAge({ birthDay: user.birthDay })
      },
      {
        title: 'Company',
        override: companyName
      },
      {
        title: 'Walk in',
        override: ({ user }) => (user.partial ? 'Yes' : 'No')
      },
      {
        title: 'Birth Day',
        override: ({ user }) => DateUtil.formatDate(user.birthDay)
      }
    ];
  }

  /**
   * Returns the user data that can be passed into the ehs-user-info component.
   * This will "merge" the user-payment with a user, if available.
   */
  public getUserFromUserPayment({
    userPayment,
    user
  }: {
    /**
     * The user-payment to use as the base information.
     */
    userPayment: UserPayment;
    /**
     * The user that will override the provided data within the user-payments
     * calculated data. This should be the updated user.
     */
    user?: User;
  }): Partial<User> | Partial<PartialUser> {
    return {
      ...userPayment,
      ...user
    };
  }

  /**
   * The list of columns displayed on the user-payments component.
   *
   * **note** this is just a starting point, using essentially the same
   * columns as the user-criticals version
   */
  public getUserPaymentsColumns({
    userPayment
  }: {
    userPayment: UserPayment;
  }): EhsUserInfoColumn[] {
    return [
      {
        title: 'First Name',
        prop: 'firstName',
        nameCase: true
      },
      {
        title: 'Last Name',
        prop: 'lastName',
        nameCase: true
      },
      {
        title: 'Phone',
        prop: 'phone'
      },
      {
        // **Note** this property has a template override
        // check the component for implementation
        title: 'Email',
        prop: 'email',
        templateOverride: true
      },
      {
        title: 'Gender',
        prop: 'gender',
        titleCase: true
      },
      {
        title: 'Age',
        override: ({ user }) =>
          '' + UserUtil.getAge({ birthDay: user.birthDay })
      },
      {
        title: 'Company',
        override: userPayment.companyName
      },
      {
        title: 'Walk in',
        override: ({ user }) => (user.partial ? 'Yes' : 'No')
      },
      {
        title: 'Person Type',
        prop: 'personType',
        titleCase: true
      }
    ];
  }

  /**
   * Returns the user data that can be passed into the ehs-user-info component.
   * This will "merge" the user-payment with a user, if available.
   */
  public getUserFromHomeTest({
    userHomeTest,
    user
  }: {
    /**
     * The user-payment to use as the base information.
     */
    userHomeTest: UserHomeTest;
    /**
     * The user that will override the provided data within the user-payments
     * calculated data. This should be the updated user.
     */
    user?: User;
  }): Partial<User> | Partial<PartialUser> {
    return {
      ...userHomeTest,
      ...user
    };
  }

  /**
   * The list of columns displayed on the user-payments component.
   *
   * **note** this is just a starting point, using essentially the same
   * columns as the user-criticals version
   */
  public getUserHomeTestColumns({
    userHomeTest
  }: {
    userHomeTest: UserHomeTest;
  }): EhsUserInfoColumn[] {
    return [
      {
        title: 'First Name',
        prop: 'firstName',
        nameCase: true
      },
      {
        title: 'Last Name',
        prop: 'lastName',
        nameCase: true
      },
      {
        title: 'Phone',
        prop: 'phone'
      },
      {
        // **Note** this property has a template override
        // check the component for implementation
        title: 'Email',
        prop: 'email',
        templateOverride: true
      },
      {
        title: 'Gender',
        prop: 'gender',
        titleCase: true
      },
      {
        title: 'Age',
        override: ({ user }) =>
          '' + UserUtil.getAge({ birthDay: user.birthDay })
      },
      {
        title: 'Company',
        override: userHomeTest.companyName
      },
      {
        title: 'Walk in',
        override: ({ user }) => (user.partial ? 'Yes' : 'No')
      },
      {
        title: 'Address',
        override: ({ user }) =>
          LocationDataUtil.getDisplayLocation({
            locationData: user as LocationData
          })
      }
    ];
  }

  public getUserFromIncentive({
    userIncentive,
    user
  }: {
    /**
     * The user-payment to use as the base information.
     */
    userIncentive: UserIncentive;
    /**
     * The user that will override the provided data within the user-payments
     * calculated data. This should be the updated user.
     */
    user?: User;
  }): Partial<User> | Partial<PartialUser> {
    return {
      ...userIncentive,
      ...user
    };
  }

  /**
   * The list of columns displayed on the user-payments component.
   *
   * **note** this is just a starting point, using essentially the same
   * columns as the user-criticals version
   */
  public getUserIncentiveColumns({
    userIncentive
  }: {
    userIncentive: UserIncentive;
  }): EhsUserInfoColumn[] {
    return [
      {
        title: 'First Name',
        prop: 'firstName',
        nameCase: true
      },
      {
        title: 'Last Name',
        prop: 'lastName',
        nameCase: true
      },
      {
        // **Note** this property has a template override
        // check the component for implementation
        title: 'Email',
        prop: 'email',
        templateOverride: true
      },
      {
        title: 'Gender',
        prop: 'gender',
        titleCase: true
      },
      {
        title: 'Age',
        override: ({ user }) =>
          '' + UserUtil.getAge({ birthDay: user.birthDay })
      },
      {
        title: 'SSN',
        prop: 'ssn'
      },
      {
        title: 'Company',
        override: userIncentive.companyName
      }
    ];
  }

  public getWalkinColumns({
    company
  }: {
    /**
     * The user's parent company, displayed as an override
     */
    company: Pick<Company, 'name'>;
  }): EhsUserInfoColumn[] {
    return [
      {
        title: 'First Name',
        prop: 'firstName',
        nameCase: true
      },
      {
        title: 'Last Name',
        prop: 'lastName',
        nameCase: true
      },
      {
        title: 'Phone',
        prop: 'phone'
      },
      {
        // **Note** this property has a template override
        // check the component for implementation
        title: 'Email',
        prop: 'email',
        templateOverride: true
      },
      {
        title: 'Gender',
        prop: 'gender',
        titleCase: true
      },
      {
        title: 'Age',
        override: ({ user }) =>
          '' + UserUtil.getAge({ birthDay: user.birthDay })
      },
      {
        title: 'Company',
        override: company?.name || ''
      },
      {
        title: 'Walk in',
        override: ({ user }) => (user.partial ? 'Yes' : 'No')
      },
      {
        title: 'Birth Day',
        override: ({ user }) => DateUtil.formatDate(user.birthDay)
      }
    ];
  }

  public getOnsiteDataFormColumns(): EhsUserInfoColumn[] {
    return [
      {
        title: 'First Name',
        prop: 'firstName',
        nameCase: true
      },
      {
        title: 'Last Name',
        prop: 'lastName',
        nameCase: true
      },
      {
        title: 'Birth Day',
        override: ({ user }) => DateUtil.formatDate(user.birthDay)
      },
      {
        title: 'Age',
        override: ({ user }) =>
          '' + UserUtil.getAge({ birthDay: user.birthDay })
      },
      {
        title: 'SSN',
        prop: 'ssn'
      },
      {
        title: 'Gender',
        prop: 'gender',
        titleCase: true
      }
    ];
  }

  public getHPFUserInfoColumns(): EhsUserInfoColumn[] {
    return [
      {
        title: 'First Name',
        prop: 'firstName',
        nameCase: true
      },
      {
        title: 'Last Name',
        prop: 'lastName',
        nameCase: true
      },
      {
        title: 'Birth Day',
        override: ({ user }) => DateUtil.formatDate(user.birthDay)
      },
      {
        title: 'Age',
        override: ({ user }) =>
          '' + UserUtil.getAge({ birthDay: user.birthDay })
      },
      {
        title: 'SSN',
        prop: 'ssn'
      },
      {
        title: 'Gender',
        prop: 'gender',
        titleCase: true
      },
      {
        title: 'Email',
        prop: 'email'
      }
    ];
  }
}
