import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ErrorReportFacade } from '../error-report/error-report.facade';
import { healthProgramTeamActions } from './health-program-team.actions';
import { HealthProgramTeamHttpService } from '../../http/health-program-team-http.service';

@Injectable()
export class HealthProgramTeamEffects {
  constructor(
    private actions$: Actions,
    private healthProgramTeamHttp: HealthProgramTeamHttpService,
    private errorReportFacade: ErrorReportFacade
  ) {}

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(healthProgramTeamActions.list),
      mergeMap((action) =>
        this.healthProgramTeamHttp.list().pipe(
          map((entities) => healthProgramTeamActions.listSuccess({ entities })),
          catchError((err) => [
            healthProgramTeamActions.listFailed({ error: err }),
            this.errorReportFacade.create({ action, err })
          ])
        )
      )
    )
  );

  listTeamsByProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(healthProgramTeamActions.listTeamsByProgram),
      mergeMap((action) =>
        this.healthProgramTeamHttp
          .listTeamsByProgram(action.healthProgramIds || [])
          .pipe(
            map((entities) =>
              healthProgramTeamActions.listTeamsByProgramSuccess({
                entities
              })
            ),
            catchError((err) => [
              healthProgramTeamActions.listTeamsByProgramFailed({ error: err }),
              this.errorReportFacade.create({ action, err })
            ])
          )
      )
    )
  );

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(healthProgramTeamActions.get),
      mergeMap((action) =>
        this.healthProgramTeamHttp.get(action.id).pipe(
          map((entity) => healthProgramTeamActions.getSuccess({ entity })),
          catchError((err: HttpErrorResponse) => [
            healthProgramTeamActions.getFailed({ error: err.error }),
            this.errorReportFacade.create({
              err: err.error,
              action
            })
          ])
        )
      )
    )
  );

  updateHealthProgramTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(healthProgramTeamActions.updateHealthProgramTeam),
      mergeMap((action) =>
        this.healthProgramTeamHttp
          .update({
            entity: action.healthProgramTeam,
            joining: action.joining
          })
          .pipe(
            map((healthProgramTeam) =>
              healthProgramTeamActions.updateHealthProgramTeamSuccess({
                entity: healthProgramTeam
              })
            ),
            catchError((err: HttpErrorResponse) => [
              this.errorReportFacade.create({
                err: err.error,
                action
              })
            ])
          )
      )
    )
  );

  createHealthProgramTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(healthProgramTeamActions.createHealthProgramTeam),
      mergeMap((action) =>
        this.healthProgramTeamHttp
          .create({
            entity: action.healthProgramTeam,
            createdBy: action.createdBy
          })
          .pipe(
            map((healthProgramTeam) =>
              healthProgramTeamActions.createHealthProgramTeamSuccess({
                entity: healthProgramTeam
              })
            ),
            catchError((err: HttpErrorResponse) => [
              this.errorReportFacade.create({
                err: err.error,
                action
              })
            ])
          )
      )
    )
  );

  getUserDetailsByTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(healthProgramTeamActions.getUserDetailsByTeam),
      mergeMap((action) =>
        this.healthProgramTeamHttp
          .getUserDetailsByTeam(action.healthProgramIds)
          .pipe(
            map((entities) =>
              healthProgramTeamActions.getUserDetailsByTeamSuccess({
                entities
              })
            ),
            catchError((err: HttpErrorResponse) => [
              this.errorReportFacade.create({
                err: err.error,
                action
              })
            ])
          )
      )
    )
  );

  getTeamAward$ = createEffect(() =>
    this.actions$.pipe(
      ofType(healthProgramTeamActions.getTeamAwards),
      mergeMap((action) =>
        this.healthProgramTeamHttp
          .getTeamAwards(action.healthProgramTeamId)
          .pipe(
            map((entities) =>
              healthProgramTeamActions.getTeamAwardsSuccess({
                entities
              })
            ),
            catchError((err: HttpErrorResponse) => [
              this.errorReportFacade.create({
                err: err.error,
                action
              })
            ])
          )
      )
    )
  );
}
