import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AdminUser,
  getId,
  isOnsiteUserRegistration,
  UserRegistrationId
} from '@common';
import { CLIENT_ADMIN_CORE_INJECTION_TOKEN } from '@ehs-ngrx/common';
import { EhsConfirmDialogService } from '@ehs-ui';
import {
  AdminUserRegistrationHttpService,
  AdminUserResultHttpService,
  AdminUserVaccinationHttpService
} from '@http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  tap,
  withLatestFrom,
  take
} from 'rxjs/operators';
import { userRegistrationNgrxActions } from './user-registration-ngrx.actions';
import { UserRegistrationNgrxFacade } from './user-registration-ngrx.facade';

@Injectable()
export class UserRegistrationNgrxEffects {
  constructor(
    @Inject(CLIENT_ADMIN_CORE_INJECTION_TOKEN)
    private clientAdmin: boolean,
    private actions$: Actions,
    private userRegistrationFacade: UserRegistrationNgrxFacade,
    // Http
    private adminUserRegistrationHttp: AdminUserRegistrationHttpService,
    private adminUserResultHttp: AdminUserResultHttpService,
    private adminUserVaccinationHttp: AdminUserVaccinationHttpService,
    private snackBar: MatSnackBar,
    private confirmDialog: EhsConfirmDialogService
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.get.req),
      mergeMap((action) =>
        this.adminUserRegistrationHttp
          .getUserRegistrationById({
            userRegistration: action.payload.id
          })
          .pipe(
            map((entity) =>
              userRegistrationNgrxActions.get.success({ entity })
            ),
            catchError(() => [userRegistrationNgrxActions.get.failed()])
          )
      )
    )
  );

  updateOptionalTests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.updateOptionalTests.req),
      mergeMap(({ payload }) =>
        this.adminUserRegistrationHttp.updateOptionalTests(payload).pipe(
          map((entity) =>
            userRegistrationNgrxActions.updateOptionalTests.success(entity)
          ),
          catchError(() => [
            userRegistrationNgrxActions.updateOptionalTests.failed()
          ])
        )
      )
    )
  );

  updateAdditionalTests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.updateAdditionalTests.req),
      mergeMap(({ payload }) =>
        this.adminUserRegistrationHttp.updateAdditionalTests(payload).pipe(
          map((entity) =>
            userRegistrationNgrxActions.updateAdditionalTests.success(entity)
          ),
          catchError(() => [
            userRegistrationNgrxActions.updateAdditionalTests.failed()
          ])
        )
      )
    )
  );

  updateServiceTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.updateServiceTypes.req),
      mergeMap(({ payload }) =>
        this.adminUserRegistrationHttp.updateServiceTypes(payload).pipe(
          map((entity) => {
            if (isOnsiteUserRegistration(entity) && !entity.vaccination) {
              entity.vaccination = undefined;
            }

            return userRegistrationNgrxActions.updateServiceTypes.success(
              entity
            );
          }),
          catchError(() => [
            userRegistrationNgrxActions.updateServiceTypes.failed()
          ])
        )
      )
    )
  );

  updateRegistrationDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.updateRegistrationDetails.req),
      mergeMap(({ payload }) =>
        this.adminUserRegistrationHttp.updateRegistrationDetails(payload).pipe(
          tap(
            () => this.snackBar.open('Registration details updated', 'Ok'),
            () => this.snackBar.open('Oops! Something went wrong', 'Ok')
          ),
          map((entity) => {
            return userRegistrationNgrxActions.updateRegistrationDetails.success(
              entity
            );
          }),
          catchError(() => [
            userRegistrationNgrxActions.updateServiceTypes.failed()
          ])
        )
      )
    )
  );

  updateHealthProviderUploadNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.updateNotes.req),
      mergeMap(({ payload }) =>
        this.adminUserRegistrationHttp
          .updateNotes({ userRegistration: payload.entity })
          .pipe(
            map((userRegistration) =>
              userRegistrationNgrxActions.updateNotes.success({
                entity: userRegistration
              })
            ),
            catchError(() => [userRegistrationNgrxActions.updateNotes.failed()])
          )
      )
    )
  );

  changeOnsiteEventDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.changeOnsiteEventDate.req),
      mergeMap(({ payload }) =>
        this.adminUserRegistrationHttp
          .changeOnsiteEventDate({ userRegistration: payload.entity })
          .pipe(
            map((entity) =>
              userRegistrationNgrxActions.changeOnsiteEventDate.success({
                entity
              })
            ),
            catchError(() => [
              userRegistrationNgrxActions.updateServiceTypes.failed()
            ])
          )
      )
    )
  );

  listAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.listAsAdmin.req),
      mergeMap((action) =>
        this.adminUserRegistrationHttp
          .listUserRegistrations(action.payload, {
            clientAdmin: this.clientAdmin
          })
          .pipe(
            map(
              (entities) =>
                userRegistrationNgrxActions.listAsAdmin.success({
                  entities
                }),
              catchError(() => [
                userRegistrationNgrxActions.listAsAdmin.failed()
              ])
            )
          )
      )
    )
  );

  listByFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.listByFilter.req),
      withLatestFrom(this.userRegistrationFacade.pageNumber$),
      mergeMap(([{ payload }, pageNumber]) =>
        this.adminUserRegistrationHttp
          .listByFilter({ ...payload, pageNumber: pageNumber || 1 })
          .pipe(
            map(({ healthProviderUploads, hasNext }) =>
              userRegistrationNgrxActions.listByFilter.success({
                healthProviderUploads,
                hasNext
              })
            ),
            catchError(() => [
              userRegistrationNgrxActions.listByFilter.failed()
            ])
          )
      )
    )
  );

  listByCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.listByCompany.req),
      mergeMap(({ payload }) =>
        this.adminUserRegistrationHttp
          .listByCompany({ company: payload.company })
          .pipe(
            tap((userRegistrations) => console.log('tst')),
            map((userRegistrations) =>
              userRegistrationNgrxActions.listByCompany.success({
                userRegistrations
              })
            ),
            catchError(() => [
              userRegistrationNgrxActions.listByCompany.failed()
            ])
          )
      )
    )
  );

  getUserReq$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.getUserReq.req),
      mergeMap((action) =>
        this.adminUserRegistrationHttp
          .getUserReq(action.payload.userRegistration)
          .pipe(
            map((userRequisition) =>
              userRegistrationNgrxActions.getUserReq.success({
                userRegistration: action.payload.userRegistration,
                userRequisition
              })
            ),
            catchError(() => [userRegistrationNgrxActions.getUserReq.failed()])
          )
      )
    )
  );

  getUserReqs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.getUserReqs.req),
      mergeMap((action) =>
        this.adminUserRegistrationHttp
          .getUserReqs(action.payload.userRegistrations)
          .pipe(
            map((userRequisitions) =>
              userRegistrationNgrxActions.getUserReqs.success({
                userRequisitions
              })
            ),
            catchError(() => [userRegistrationNgrxActions.getUserReqs.failed()])
          )
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.remove.req),
      mergeMap((action) =>
        this.confirmDialog
          .open({
            title: 'Remove Result & Registration',
            message:
              'Are you sure you want to remove the result and registration?'
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                return [];
              }

              return this.adminUserResultHttp
                .removeUserResult({
                  userRegistration: action.payload.id,
                  removeRegistration: true
                })
                .pipe(
                  tap(
                    () =>
                      this.snackBar.open(
                        'Result and registration removed',
                        'Ok'
                      ),
                    () =>
                      this.snackBar.open(
                        'Oops! Something went wrong with removing result and registration',
                        'Ok'
                      )
                  ),
                  map((entity) =>
                    userRegistrationNgrxActions.remove.success({
                      id: UserRegistrationId(getId(entity.userRegistration))
                    })
                  ),
                  catchError(() => [
                    userRegistrationNgrxActions.remove.failed()
                  ])
                );
            })
          )
      )
    )
  );

  removeVaccinationAndRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.removeVaccinationAndRegistration.req),
      mergeMap((action) =>
        this.confirmDialog
          .open({
            title: 'Remove Vaccination & Registration',
            message:
              'Are you sure you want to remove the vaccination and registration?'
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                return [];
              }

              return this.adminUserVaccinationHttp
                .removeUserVaccinationAndRegistration({
                  userRegistration: action.payload.id,
                  removeRegistration: true
                })
                .pipe(
                  tap(
                    () =>
                      this.snackBar.open(
                        'Vaccination and registration removed',
                        'Ok'
                      ),
                    () =>
                      this.snackBar.open(
                        'Oops! Something went wrong with removing vaccination and registration',
                        'Ok'
                      )
                  ),
                  map((entity) =>
                    userRegistrationNgrxActions.removeVaccinationAndRegistration.success(
                      {
                        id: UserRegistrationId(getId(entity.userRegistration))
                      }
                    )
                  ),
                  catchError(() => [
                    userRegistrationNgrxActions.removeVaccinationAndRegistration.failed()
                  ])
                );
            }),
            tap(() => {
              // Reload the page

              return window.location.reload();
            })
          )
      )
    )
  );

  removeRegistrationOnly$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.removeRegistrationOnly.req),
      mergeMap((action) =>
        this.confirmDialog
          .open({
            title: 'Remove Registration',
            message: 'Are you sure you want to remove this registration?'
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                return [];
              }

              return this.adminUserRegistrationHttp
                .removeRegistration({
                  id: action.payload.id,
                  clientAdmin: this.clientAdmin
                })
                .pipe(
                  tap(
                    () => this.snackBar.open('Registration removed', 'Ok'),
                    () =>
                      this.snackBar.open(
                        'Oops! Something went wrong with removing registration',
                        'Ok'
                      )
                  ),
                  map((entity) =>
                    userRegistrationNgrxActions.removeRegistrationOnly.success({
                      id: UserRegistrationId(getId(entity))
                    })
                  ),
                  catchError(() => [
                    userRegistrationNgrxActions.removeRegistrationOnly.failed()
                  ])
                );
            })
          )
      )
    )
  );

  resendConfirmationEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.resendConfirmationEmail.req),
      mergeMap((action) =>
        this.confirmDialog
          .open({
            title: 'Resend Confirmation',
            message: 'Are you sure you want to resend the confirmation?'
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                return [];
              }

              return this.adminUserRegistrationHttp
                .resendConfirmationEmail({
                  id: action.payload.id
                })
                .pipe(
                  tap(
                    () => this.snackBar.open('Confirmation resent', 'Ok'),
                    () =>
                      this.snackBar.open(
                        'Oops! Something went wrong with resending confirmation',
                        'Ok'
                      )
                  ),
                  map((entity) =>
                    userRegistrationNgrxActions.resendConfirmationEmail.success(
                      {
                        userRegistration: entity
                      }
                    )
                  ),
                  catchError(() => [
                    userRegistrationNgrxActions.resendConfirmationEmail.failed()
                  ])
                );
            })
          )
      )
    )
  );

  release$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.release.req),
      mergeMap((action) =>
        this.confirmDialog
          .open({
            title: 'Release Registration',
            message: 'Are you sure you want to release the registration(s)?'
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                return [];
              }

              return this.adminUserRegistrationHttp
                .releaseUserRegistrations({
                  ids: action.payload.ids
                })
                .pipe(
                  tap(
                    () =>
                      this.snackBar.open('Released user registration(s)', 'Ok'),
                    () =>
                      this.snackBar.open(
                        'Oops, failed to release user registration(s)',
                        'Ok'
                      )
                  ),
                  map((entities) =>
                    userRegistrationNgrxActions.release.success({ entities })
                  ),
                  catchError(() => [
                    userRegistrationNgrxActions.release.failed()
                  ])
                );
            })
          )
      )
    )
  );

  sendToLabPrompt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.sendToLab.prompt),
      mergeMap((action) => {
        const confirmationMessage = ({
          admin,
          ids
        }: {
          admin?: AdminUser;
          eventLocation?: string;
          ids?: UserRegistrationId[];
        }) => {
          console.log('Admin', admin);

          if (ids && ids.length) {
            return `Are you sure you want to send the ${action.payload.ids?.length} selected registrations to the Lab?`;
          }

          if (admin?.email) {
            return `Are you sure you want to send all registrations reviewed by ${admin?.email} in this event to the Lab?`;
          }

          return 'Are you sure you want to send all registrations in this event to the Lab?';
        };

        return this.confirmDialog
          .open({
            title: 'Send Order(s) to Lab',
            message: confirmationMessage(action.payload)
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                this.snackBar.open('Cancelled', 'Ok');

                return [userRegistrationNgrxActions.sendToLab.canceled()];
              }

              return [
                userRegistrationNgrxActions.sendToLab.req(action.payload)
              ];
            })
          );
      })
    )
  );

  sendToLab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.sendToLab.req),
      mergeMap((action) =>
        this.adminUserRegistrationHttp
          .sendOrdersToLab({
            ids: action.payload.ids,
            eventLocation: action.payload.eventLocation,
            resend: action.payload.resend,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            admin: getId(action.payload.admin!)
          })
          .pipe(
            tap(
              () => this.snackBar.open('Order(s) send to lab', 'Ok'),
              () =>
                this.snackBar.open('Oops, failed to send(s) order to lab', 'Ok')
            ),
            map((entities) =>
              userRegistrationNgrxActions.sendToLab.success({ entities })
            ),
            catchError(() => [userRegistrationNgrxActions.sendToLab.failed()])
          )
      )
    )
  );

  markNoShowPrompt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.markNoShow.prompt),
      mergeMap((action) => {
        const confirmationMessage = () => {
          return 'Are you sure you want to mark the selected un-resulted registrations in this event as no show?';
        };

        return this.confirmDialog
          .open({
            title: 'Mark Registrations as No Show',
            message: confirmationMessage()
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                this.snackBar.open('Cancelled', 'Ok');

                return [userRegistrationNgrxActions.markNoShow.canceled()];
              }

              if (typeof confirm === 'boolean') {
                return [
                  userRegistrationNgrxActions.markNoShow.req({
                    ...action.payload
                  })
                ];
              }

              return [
                userRegistrationNgrxActions.markNoShow.req({
                  ...action.payload
                })
              ];
            })
          );
      })
    )
  );

  markNoShow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.markNoShow.req),
      mergeMap((action) =>
        this.adminUserRegistrationHttp
          .markNoShow({
            userRegistrations: action.payload.userRegistrations
          })
          .pipe(
            tap(
              () =>
                this.snackBar.open(
                  'Marking applicable Registration(s) as no show',
                  'Ok'
                ),
              () =>
                this.snackBar.open(
                  'Oops, failed to mark registration(s) as no show',
                  'Ok'
                )
            ),
            map(() =>
              userRegistrationNgrxActions.markNoShow.success({
                entities: action.payload.userRegistrations
              })
            ),
            catchError(() => [userRegistrationNgrxActions.markNoShow.failed()])
          )
      )
    )
  );

  removeVaccinationPrompt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.removeVaccination.prompt),
      mergeMap((action) => {
        return this.confirmDialog
          .open({
            title: 'Remove Vaccination from Registration',
            message:
              'Are you sure you remove vaccination from the User Registration record?'
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                this.snackBar.open('Cancelled', 'Ok');

                return [
                  userRegistrationNgrxActions.removeVaccination.canceled()
                ];
              }

              return [
                userRegistrationNgrxActions.removeVaccination.req({
                  ...action.payload
                })
              ];
            })
          );
      })
    )
  );

  removeVaccination$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.removeVaccination.req),
      mergeMap((action) =>
        this.adminUserRegistrationHttp
          .removeVaccination({
            userRegistrations: action.payload.userRegistrations
          })
          .pipe(
            tap(
              () =>
                this.snackBar.open(
                  'Marking applicable Registration(s) as remove Vaccination',
                  'Ok'
                ),
              () =>
                this.snackBar.open(
                  'Oops, failed to mark registration(s) as remove Vaccination',
                  'Ok'
                )
            ),
            map(() => userRegistrationNgrxActions.removeVaccination.success()),
            catchError(() => [
              userRegistrationNgrxActions.removeVaccination.failed()
            ])
          )
      )
    )
  );

  markCompletedPrompt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.markCompleted.prompt),
      mergeMap((action) => {
        const confirmationMessage = () => {
          return 'Are you sure you want to mark the selected no-show registrations in this event as completed';
        };

        return this.confirmDialog
          .open({
            title: 'Mark Registrations as Completed',
            message: confirmationMessage()
          })
          .pipe(
            mergeMap((confirm) => {
              if (!confirm) {
                this.snackBar.open('Cancelled', 'Ok');

                return [userRegistrationNgrxActions.markCompleted.canceled()];
              }

              return [
                userRegistrationNgrxActions.markCompleted.req(action.payload)
              ];
            })
          );
      })
    )
  );

  markCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.markCompleted.req),
      mergeMap((action) =>
        this.adminUserRegistrationHttp
          .markCompleted({
            userRegistrations: action.payload.userRegistrations
          })
          .pipe(
            tap(
              () =>
                this.snackBar.open(
                  'Marking applicable Registration(s) as completed',
                  'Ok'
                ),
              () =>
                this.snackBar.open(
                  'Oops, failed to mark registration(s) as completed',
                  'Ok'
                )
            ),
            map(() =>
              userRegistrationNgrxActions.markCompleted.success({
                entities: action.payload.userRegistrations
              })
            ),
            catchError(() => [
              userRegistrationNgrxActions.markCompleted.failed()
            ])
          )
      )
    )
  );

  createWalkinRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.createWalkinRegistration.req),
      mergeMap(({ payload: { entity } }) =>
        this.adminUserRegistrationHttp
          .createWalkinRegistration({
            userRegistration: entity,
            clientAdmin: this.clientAdmin
          })
          .pipe(
            tap(
              () => this.snackBar.open('Created Registration', 'Ok'),
              () =>
                this.snackBar.open(
                  'Oops, there was an error creating Registration',
                  'Ok'
                )
            ),
            map((userRegistration) =>
              userRegistrationNgrxActions.createWalkinRegistration.success({
                entity: userRegistration
              })
            ),
            catchError(() => [
              userRegistrationNgrxActions.createWalkinRegistration.failed()
            ])
          )
      )
    )
  );

  createAdditionalWalkinRegistration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        userRegistrationNgrxActions.createAdditionalWalkinRegistration.req
      ),
      mergeMap(({ payload: { entity } }) =>
        this.adminUserRegistrationHttp
          .createAdditionalWalkinRegistration({
            userRegistration: entity,
            clientAdmin: this.clientAdmin
          })
          .pipe(
            tap(
              () => this.snackBar.open('Created Registration', 'Ok'),
              () =>
                this.snackBar.open(
                  'Oops, there was an error creating Registration',
                  'Ok'
                )
            ),
            map((userRegistration) =>
              userRegistrationNgrxActions.createAdditionalWalkinRegistration.success(
                {
                  entity: userRegistration
                }
              )
            ),
            catchError(() => [
              userRegistrationNgrxActions.createAdditionalWalkinRegistration.failed()
            ])
          )
      )
    )
  );

  exportToCsv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistrationNgrxActions.exportToCsv.req),
      mergeMap((action) =>
        this.adminUserRegistrationHttp
          .exportRegistrationsToCsv({
            company: action.payload.company,
            eventLocation: action.payload.eventLocation || undefined
          })
          .pipe(
            take(1),
            tap(
              () => this.snackBar.open('Registration Downloading...', 'Ok'),
              () =>
                this.snackBar.open('Oops, failed to export registrations', 'Ok')
            ),
            map((res) => {
              const blob = new Blob([res], {
                type: 'text/csv;charset=utf-8'
              });

              const fileName =
                'Registration Export - ' + new Date().toLocaleDateString();

              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');

              document.body.appendChild(a);
              a.setAttribute('style', 'display: none');
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove();

              return userRegistrationNgrxActions.exportToCsv.success();
            }),
            catchError(() => [userRegistrationNgrxActions.exportToCsv.failed()])
          )
      )
    )
  );
}
