import { Injectable } from '@angular/core';
import {
  AdminUserScopes,
  isOffsiteUserRegistration,
  isOnsiteUserRegistration,
  OffsiteUserRegistration
} from '@common';
import { UserRegistrationListActionColumn } from './user-registration-list-table-column';

@Injectable({
  providedIn: 'root'
})
export class EhsUserRegistrationListTableService {
  public getActionColumns(
    scopes: AdminUserScopes[]
  ): UserRegistrationListActionColumn[] {
    return (
      [
        {
          type: 'remove',
          scope: AdminUserScopes.UPDATE_REG,
          name: 'Remove Registration'
        },
        {
          type: 'reschedule',
          scope: AdminUserScopes.UPDATE_REG,
          name: 'Reschedule'
        },
        {
          type: 'edit',
          scope: AdminUserScopes.UPDATE_REG,
          name: 'Edit Registration'
        },
        {
          type: 'view',
          name: 'View Raw Data'
        },
        {
          type: 'viewEventService',
          name: 'View Event Service'
        },
        {
          type: 'resendOrder',
          name: 'Resend Lab Order'
        },
        {
          type: 'resendConfirmation',
          name: 'Resend Confirmation',
          scope: AdminUserScopes.RESEND_EMAIL,
          displayFn: ({ userRegistration }) => {
            return (
              isOnsiteUserRegistration(userRegistration) ||
              isOffsiteUserRegistration(userRegistration)
            );
          }
        },
        {
          type: 'sendReqFax',
          name: 'Fax Lifepoint Requisition',
          displayFn: ({ userRegistration }) => {
            return !!(userRegistration as OffsiteUserRegistration)
              .userRequisition;
          }
        }
      ] as Array<UserRegistrationListActionColumn>
    ).filter(({ scope }) => (scope ? scopes.includes(scope) : true));
  }
}
