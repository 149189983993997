import { ObjectId } from 'mongodb';
import { ServiceLabType } from '../event-service/service-lab-type';
import { ServiceType } from '../event-service/service-type';
import { User } from '../user';
import { UserRegistration } from '../user-registration';
import { UserRequisition } from '../user-requisition';
import { BaseSiteUserRegistration } from './base-site-user-registration';

// tslint:disable-next-line: no-empty-interface
export interface OffsiteUserRegistration extends BaseSiteUserRegistration {
  serviceType: ServiceType.OFFSITE;
  /**
   * The corresponding user-registration with this user-registration.
   * Only applies to offsite user-registrations, **and** onsites with an external lab
   */
  userRequisition?: string | ObjectId | UserRequisition;

  /**
   * The snapshot of the user saved, this is to handle cases
   * where the user updates their name or information.
   * TODO: specify what we are actually saving
   */
  userSnapshot?: Partial<User>;
  /**
   * The lab type for the event.
   */
  labType: ServiceLabType;
  /**
   * The idempotency-key passed to square when making "self-pay"
   * calls. Shouldn't be normally returned back to user's.
   *
   * TODO: I'm not even 100% sure if we need to save this
   */
  squareIdempotencyKey?: string;

  /** Handles case where lifepoint api fails to send reg to lab  */
  labOrderFailed?: boolean;
  /**
   * When results have returned from lifepoint
   */
  isResulted?: boolean;
}

/**
 * Type-guard for offsite user-registrations
 */
export const isOffsiteUserRegistration = (
  userRegistration: UserRegistration
): userRegistration is OffsiteUserRegistration =>
  userRegistration?.serviceType === ServiceType.OFFSITE;
