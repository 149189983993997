import { ProgramStatus } from '../constants/health-program-values';
import { HealthProgram } from '../models/health-program/health-program';
import {
  isWalkingProgram,
  WalkingProgramType
} from '../models/health-program/walking-program';
import { DateUtil } from './date-util';

export class HealthProgramUtil {
  /**
   * Return the health program with the earliest start date.
   */
  public static getEarliestHealthProgram(
    healthPrograms: HealthProgram[]
  ): HealthProgram {
    return (healthPrograms || []).reduce((earliest, healthProgram) => {
      if (!earliest || healthProgram.startDate < earliest.startDate) {
        return healthProgram;
      }

      return earliest;
    }, null);
  }

  public static getProgramStatus(loadedProgram: HealthProgram) {
    if (
      !loadedProgram ||
      !DateUtil.isValidDate(loadedProgram.registrationStartDate) ||
      !DateUtil.isValidDate(loadedProgram.startDate) ||
      !DateUtil.isValidDate(loadedProgram.endDate) ||
      !DateUtil.isValidDate(loadedProgram.lastSyncDate)
    ) {
      return '';
    }

    // We want to compare against the user's current local time
    const today = new Date();
    // We want these dates to start at midnight (00:00:00.000)
    const registrationStartDate = DateUtil.convertFromString(
      loadedProgram.registrationStartDate
    );
    const startDate = DateUtil.convertFromString(loadedProgram.startDate);
    // We want the end date to be the end of the day
    const endDate = DateUtil.convertFromString(loadedProgram.endDate);
    const lastSyncDate = DateUtil.convertFromString(loadedProgram.lastSyncDate);

    endDate.setHours(23, 59, 59, 999);
    lastSyncDate.setHours(23, 59, 59, 999);

    if (!registrationStartDate || !startDate || !endDate || !lastSyncDate) {
      return '';
    }

    // If the registration start date is in the future, the program is coming soon
    if (registrationStartDate > today) {
      return ProgramStatus.COMING_SOON;
    }

    // If today is between the start of registration start date and the start of the start date,
    // the program is open for registration
    if (today >= registrationStartDate && today < startDate) {
      return ProgramStatus.REGISTRATION_OPEN;
    }

    // If today is between the start of the start date and end of the end date,
    // the program is active
    if (today >= startDate && today <= endDate) {
      return ProgramStatus.ACTIVE;
    }

    // If today is after the end of the end date and up to the end of the last sync date,
    // the program is ending soon
    if (today > endDate && today <= lastSyncDate) {
      return ProgramStatus.ENDING_SOON;
    }

    // Otherwise, the program has ended
    return ProgramStatus.ENDED;
  }

  /**
   * Does the given health program have the necessary data to be a destination program
   */
  public static isValidDestinationProgram(healthProgram: HealthProgram) {
    if (isWalkingProgram(healthProgram)) {
      if (healthProgram.walkingProgramType === WalkingProgramType.DESTINATION) {
        if (
          healthProgram?.startingAddress?.length &&
          healthProgram?.destinationAddress?.length
        ) {
          return true;
        }
      }
    }

    return false;
  }
}
