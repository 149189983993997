import { DbDocument } from '../db-document';

export enum ActivityBadgeType {
  STEPS = 'steps',
  SLEEP = 'sleep',
  ACTIVITY = 'activity',
  PERCENTAGE = 'percentage'
}

export enum ActivityBadgeAudience {
  COMPANY = 'company',
  GLOBAL = 'global'
}

export enum ActivityBadgeColor {
  VIBRANT_BLUE = 'vibrant-blue',
  ELECTRIC_LIME = 'electric-lime',
  HOT_PINK = 'hot-pink',
  SUNNY_ORANGE = 'sunny-orange',
  NEON_GREEN = 'neon-green',
  BRIGHT_PURPLE = 'bright-purple',
  TURQUOISE = 'turquoise',
  CANARY_YELLOW = 'canary-yellow',
  CORAL_RED = 'coral-red',
  SKY_BLUE = 'sky-blue'
}

export const activityBadgeColorsMap: { [key in ActivityBadgeColor]: string } = {
  [ActivityBadgeColor.VIBRANT_BLUE]: '#00BFFF',
  [ActivityBadgeColor.ELECTRIC_LIME]: '#CCFF00',
  [ActivityBadgeColor.BRIGHT_PURPLE]: '#9932CC',
  [ActivityBadgeColor.CANARY_YELLOW]: '#FFFF00',
  [ActivityBadgeColor.HOT_PINK]: '#FF69B4',
  [ActivityBadgeColor.CORAL_RED]: '#FF7F50',
  [ActivityBadgeColor.SUNNY_ORANGE]: '#FF8C00',
  [ActivityBadgeColor.NEON_GREEN]: '#00FF00',
  [ActivityBadgeColor.SKY_BLUE]: '#87CEEB',
  [ActivityBadgeColor.TURQUOISE]: '#40E0D0'
};

export enum ActivityBadgeTarget {
  INDIVIDUAL = 'individual',
  TEAM = 'team'
}

/**
 * A badge to be given to a user or team when completing milestones.
 */
export interface BaseActivityBadge extends DbDocument {
  // Title of the badge
  title: string;
  // The type of badge
  type: ActivityBadgeType;

  completionText: string;

  color: string;
  // How the activity badge will be calculated, either by company of globally
  audience: ActivityBadgeAudience;

  target: ActivityBadgeTarget;
}

export interface StepsActivityBadge extends BaseActivityBadge {
  type: ActivityBadgeType.STEPS;
  steps: number;
}

export interface PercentageActivityBadge extends BaseActivityBadge {
  type: ActivityBadgeType.PERCENTAGE;
  percentage: number;
}

export type ActivityBadge = StepsActivityBadge | PercentageActivityBadge;
