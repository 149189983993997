import { createReducer, Action, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { selectId } from '../utils/select-id';
import { ActivityBadge, HealthProgramTeam, TeamMemberDetails } from '@common';
import { healthProgramTeamActions } from './health-program-team.actions';

export interface HealthProgramTeamState extends EntityState<HealthProgramTeam> {
  loading?: boolean;

  /**
   * Map of relevant user details by the team id
   */
  userDetailsByTeams?: Record<string, TeamMemberDetails[]>;

  /**
   * Array of the awards the current user team has been awarded
   */
  teamAwards?: ActivityBadge[];
}

const adapter = createEntityAdapter<HealthProgramTeam>({
  selectId
});

const reducer = createReducer<HealthProgramTeamState>(
  adapter.getInitialState({}),
  on(healthProgramTeamActions.clear, (state) => adapter.removeAll(state)),
  on(healthProgramTeamActions.list, (state) => ({ ...state, loading: true })),
  on(healthProgramTeamActions.listSuccess, (state, { entities }) =>
    adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(healthProgramTeamActions.listFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(healthProgramTeamActions.listTeamsByProgram, (state) => ({
    ...state,
    loading: true
  })),
  on(
    healthProgramTeamActions.listTeamsByProgramSuccess,
    (state, { entities }) =>
      adapter.upsertMany(entities, { ...state, loading: false })
  ),
  on(healthProgramTeamActions.listTeamsByProgramFailed, (state) => ({
    ...state,
    loading: false
  })),

  on(healthProgramTeamActions.get, (state) => ({ ...state, loading: true })),
  on(healthProgramTeamActions.getSuccess, (state, { entity }) =>
    adapter.upsertOne(entity, { ...state, loading: false })
  ),
  on(healthProgramTeamActions.getFailed, (state) => ({
    ...state,
    loading: false
  })),
  on(healthProgramTeamActions.getUserDetailsByTeam, (state) => ({
    ...state,
    loading: true
  })),
  on(
    healthProgramTeamActions.getUserDetailsByTeamSuccess,
    (state, { entities }) => ({
      ...state,
      userDetailsByTeams: entities,
      loading: false
    })
  ),
  on(healthProgramTeamActions.getUserDetailsByTeamFailed, (state) => ({
    ...state,
    loading: false
  })),
  on(healthProgramTeamActions.getTeamAwards, (state) => ({
    ...state,
    loading: true
  })),
  on(healthProgramTeamActions.getTeamAwardsSuccess, (state, { entities }) => ({
    ...state,
    teamAwards: entities,
    loading: false
  })),
  on(healthProgramTeamActions.getTeamAwardsFailed, (state) => ({
    ...state,
    loading: false
  }))
);

export function HealthProgramTeamReducer(
  state: HealthProgramTeamState,
  action: Action
) {
  return reducer(state, action);
}
