import {
  ActivityBadge,
  CommonResponse,
  HealthProgramTeam,
  TeamMemberDetails
} from '@common';
import { createAction, props } from '@ngrx/store';

export const healthProgramTeamActions = {
  clear: createAction('[HEALTH_PROGRAM_TEAM] CLEAR'),

  list: createAction('[HEALTH_PROGRAM_TEAM] LIST'),
  listSuccess: createAction(
    '[HEALTH_PROGRAM_TEAM] LIST_SUCCESS',
    props<{
      entities: HealthProgramTeam[];
    }>()
  ),
  listFailed: createAction(
    '[HEALTH_PROGRAM_TEAM] LIST_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),
  listTeamsByProgram: createAction(
    '[HEALTH_PROGRAM_TEAM] LIST_TEAMS_BY_PROGRAM',
    props<{
      healthProgramIds: string[];
    }>()
  ),
  listTeamsByProgramSuccess: createAction(
    '[HEALTH_PROGRAM_TEAM] LIST_TEAMS_BY_PROGRAM_SUCCESS',
    props<{
      entities: HealthProgramTeam[];
    }>()
  ),
  listTeamsByProgramFailed: createAction(
    '[HEALTH_PROGRAM_TEAM] LIST_TEAMS_BY_PROGRAM_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),
  get: createAction(
    '[HEALTH_PROGRAM_TEAM] GET',
    props<{
      id: string;
      saveToState?: boolean;
    }>()
  ),
  getSuccess: createAction(
    '[HEALTH_PROGRAM_TEAM] GET_SUCCESS',
    props<{
      entity: HealthProgramTeam;
    }>()
  ),
  getFailed: createAction(
    '[HEALTH_PROGRAM_TEAM] GET_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),

  updateHealthProgramTeam: createAction(
    '[HEALTH_PROGRAM_TEAM] UPDATE_HEALTH_PROGRAM_TEAM',
    props<{
      healthProgramTeam: HealthProgramTeam;
      joining?: string;
    }>()
  ),
  updateHealthProgramTeamSuccess: createAction(
    '[HEALTH_PROGRAM_TEAM] UPDATE_HEALTH_PROGRAM_TEAM_SUCCESS',
    props<{
      entity: HealthProgramTeam;
    }>()
  ),
  updateHealthProgramTeamFailed: createAction(
    '[HEALTH_PROGRAM_TEAM] UPDATE_HEALTH_PROGRAM_TEAM_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),
  createHealthProgramTeam: createAction(
    '[HEALTH_PROGRAM_TEAM] CREATE_HEALTH_PROGRAM_TEAM',
    props<{
      healthProgramTeam: Partial<HealthProgramTeam>;
      createdBy?: string;
    }>()
  ),
  createHealthProgramTeamSuccess: createAction(
    '[HEALTH_PROGRAM_TEAM] CREATE_HEALTH_PROGRAM_TEAM_SUCCESS',
    props<{
      entity: HealthProgramTeam;
    }>()
  ),
  createHealthProgramTeamFailed: createAction(
    '[HEALTH_PROGRAM_TEAM] CREATE_HEALTH_PROGRAM_TEAM_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),
  getUserDetailsByTeam: createAction(
    '[HEALTH_PROGRAM_TEAM] GET_USER_DETAILS_BY_TEAM',
    props<{
      healthProgramIds: string[];
    }>()
  ),
  getUserDetailsByTeamSuccess: createAction(
    '[HEALTH_PROGRAM_TEAM] GET_USER_DETAILS_BY_TEAM_SUCCESS',
    props<{
      entities: Record<string, TeamMemberDetails[]>;
    }>()
  ),
  getUserDetailsByTeamFailed: createAction(
    '[HEALTH_PROGRAM_TEAM] GET_USER_DETAILS_BY_TEAM_FAILED',
    props<{
      error: CommonResponse;
    }>()
  ),
  getTeamAwards: createAction(
    '[HEALTH_PROGRAM_TEAM] GET_TEAM_AWARDS',
    props<{
      healthProgramTeamId: string;
    }>()
  ),
  getTeamAwardsSuccess: createAction(
    '[HEALTH_PROGRAM_TEAM] GET_TEAM_AWARDS_SUCCESS',
    props<{
      entities: ActivityBadge[];
    }>()
  ),
  getTeamAwardsFailed: createAction(
    '[HEALTH_PROGRAM_TEAM] GET_TEAM_AWARDS_FAILED',
    props<{
      error: CommonResponse;
    }>()
  )
};
